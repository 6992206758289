/* globals bootstrap */
import view from 'bundle-text:../dom/dashboard.html'; // eslint-disable-line
import nav from 'bundle-text:../dom/_nav.html'; // eslint-disable-line
import app from './app';

/* eslint-disable */
// eslint-disable-next-line
const imgUrl = new URL('../img/Star.svg', import.meta.url); // eslint-disable-line

const trendUrlGreen = new URL(
  '../img/Liikennevalo_vihrea.svg',
  import.meta.url,
);
const trendUrlRed = new URL(
  '../img/Liikennevalo_punainen.svg',
  import.meta.url,
);
const trendUrlYellow = new URL(
  '../img/Liikennevalo_keltainen.svg',
  import.meta.url,
);
/* eslint-enable */

export default () => {
  // const footer = document.querySelector('#app-footer');
  // footer.classList.remove('position-absolute');

  const container = document.querySelector('#app-content');
  container.classList.remove('align-items-center');
  container.classList.remove('d-flex');

  const content = document.createElement('div');
  // content.classList.add('align-items-center');
  // content.classList.add('d-flex');
  // content.classList.add('justify-content-center');
  // content.classList.add('min-h-100');
  // content.classList.add('w-100');
  content.innerHTML = view;

  content.querySelector('#app-nav').innerHTML = nav;
  content.querySelector(`[href="${window.location.hash}"]`).classList.add('active');

  document.title = `Dashboard | ${app.conf.title}`;

  console.log(app.run.token);
  console.log(app.run.tokenObject);

  content.querySelector('#app-view-dashboard-age').innerHTML = `${app.run.tokenObject.user.age} years`;
  if (app.run.tokenObject.user.biologicalSex === 'female') {
    content.querySelector('#app-view-dashboard-biologicalSex').innerHTML = '<i class="fa-solid fa-venus fa-fw"></i>';
  }
  if (app.run.tokenObject.user.biologicalSex === 'male') {
    content.querySelector('#app-view-dashboard-biologicalSex').innerHTML = '<i class="fa-solid fa-mars fa-fw"></i>';
  }
  content.querySelector('#app-view-dashboard-nameFirst').innerHTML = app.run.tokenObject.user.nameFirst;
  content.querySelector('#app-view-dashboard-nameLast').innerHTML = app.run.tokenObject.user.nameLast;

  const myModal = new bootstrap.Modal(content.querySelector('#exampleModal'));
  // myModal.show();

  fetch('/api/data/dashboard', { headers: app.run.headers })
    .then(response => response.json())
    .then((json) => {
      app.lib.token.set(json._token);

      if (json.data && json.data.dailySurvey && json.data.dailySurvey[0]) {
        if (json.data.dailySurvey[0].date !== new Date().toISOString().substring(0, 10)) {
          myModal.show();
        }
      } else {
        myModal.show();
      }

      if (json.data && json.data.height && json.data.height[0]) {
        content.querySelector('#app-view-dashboard-height').innerHTML = `${json.data.height[0].data}cm`;
      }
      if (json.data && json.data.weight && json.data.weight[0]) {
        content.querySelector('#app-view-dashboard-weight').innerHTML = `${json.data.weight[0].data}kg`;
      }

      const scoreContainer = document.querySelector('#app-view-dashboard-score');
      var bar = new ProgressBar.SemiCircle(scoreContainer, {
        strokeWidth: 4,

        color: '#3384e8',
        trailColor: '#eee',
        trailWidth: 1,
        easing: 'easeInOut',
        duration: 1400,
        svgStyle: null,
        text: {
          value: '',
          alignToBottom: false
        },
        from: {color: '#3384e8'},
        to: {color: '#3384e8'},
        // Set default step function for all animate calls
        step: (state, bar) => {
          bar.path.setAttribute('stroke', state.color);
          var value = Math.round(bar.value() * 100);
          if (value === 0) {
            bar.setText('');
          } else {
            bar.setText(value);
          }
      
          bar.text.style.color = '#fff';
        }
      });
      bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
      bar.text.style.fontSize = '2rem';
      bar.text.style.bottom = '50px';
      
      bar.animate(0.75);  // Number from 0.0 to 1.0

      // dailySurvey-entries
      /*
                id="app-view-dashboard-dailySurvey-entries"></ul>

      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link text-white" href="#"><i class="fa-solid fa-chevron-right fa-2xs"></i> Entry, 19.9.2023</a>
        </li>

      */
    const dailySurveyEntries = content.querySelector('#app-view-dashboard-dailySurveyEntries');
    json.data.dailySurvey.forEach((entry) => {
      const score = JSON.parse(entry.data).score;
      const text = JSON.parse(entry.data).text;

      const listItem = document.createElement('li');
      const listItemContent = document.createElement('div');

      let content = '';

      content += `<div class="app-start-small-${score}"></div>`;
      content += score;
      content += entry.date;
      content += text;

      listItem.classList.add('nav-item');
      listItemContent.innerHTML = content;

      listItem.append(listItemContent);
      dailySurveyEntries.append(listItem);
      console.log(entry);
    });

      if (json.data && json.data.dailySurvey && json.data.dailySurvey[0]) {
        if (JSON.parse(json.data.dailySurvey[0].data).score === '1') {
          content.querySelector('#app-view-dashboard-dailySurvey').innerHTML = `
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; opacity: 0.5; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; opacity: 0.5; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; opacity: 0.5; width: 1.5rem;">
          `;
        } else if (JSON.parse(json.data.dailySurvey[0].data).score === '2') {
          content.querySelector('#app-view-dashboard-dailySurvey').innerHTML = `
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; opacity: 0.5; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; opacity: 0.5; width: 1.5rem;">
          `;
        } else if (JSON.parse(json.data.dailySurvey[0].data).score === '3') {
          content.querySelector('#app-view-dashboard-dailySurvey').innerHTML = `
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; opacity: 0.5; width: 1.5rem;">
          `;
        } else if (JSON.parse(json.data.dailySurvey[0].data).score === '4') {
          content.querySelector('#app-view-dashboard-dailySurvey').innerHTML = `
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
            <img alt="" src="${imgUrl}" style="height: 1.5rem; width: 1.5rem;">
          `;
        }
        // console.log(json.data.dailySurvey[0].data);
        // content.querySelector('#app-view-dashboard-dailySurvey').innerHTML = `${json.data.dailySurvey[0].data} / 4`;
        // <img alt="" src="../img/Star.svg" style="width: 1.5rem;">
      }
      if (json.data && json.data.dailySurvey && json.data.dailySurvey[1]) {
        let trend = '';

        if (JSON.parse(json.data.dailySurvey[0].data).score === JSON.parse(json.data.dailySurvey[1].data).score) {
          /*trend = `<span class="bg-warning p-2 rounded-circle">
                           <i class="fa-solid fa-arrow-right fa-fw"></i>
                         </span>`;*/
          trend = `<img alt="" src="${trendUrlYellow}" style="width: 0.5rem;">`;
        } else if (JSON.parse(json.data.dailySurvey[0].data).score > JSON.parse(json.data.dailySurvey[1].data).score) {
          /*trend = `<span class="bg-success p-2 rounded-circle">
                           <i class="fa-solid fa-arrow-trend-up fa-fw"></i>
                         </span>`;*/
          trend = `<img alt="" src="${trendUrlGreen}" style="width: 0.5rem;">`;
        } else if (JSON.parse(json.data.dailySurvey[0].data).score < JSON.parse(json.data.dailySurvey[1].data).score) {
          /*trend = `<span class="bg-danger p-2 rounded-circle">
                           <i class="fa-solid fa-arrow-trend-down fa-fw"></i>
                         </span>`;*/
          trend = `<img alt="" src="${trendUrlRed}" style="width: 0.5rem;">`;
        }

        content.querySelector('#app-view-dashboard-dailySurvey-trend').innerHTML = trend;
      }


      if (json.data && json.data['oura-sleep'] && json.data['oura-sleep'][0]) {
        const sleepData = JSON.parse(json.data['oura-sleep'][0].data);
        const sleepSeconds = sleepData.total_sleep_duration;
        let h = 0;
        let m = 0;
        let s = sleepSeconds;

        while (s >= 60) {
          s -= 60;
          m += 1;
        }
        while (m >= 60) {
          m -= 60;
          h += 1;
        }

        content.querySelector('#app-view-dashboard-sleep').innerHTML = `${h} h ${m} min`;
        // console.log(sleepData);
        // 4h 57min

        const awakeSeconds = sleepData.awake_time;
        const remSeconds = sleepData.rem_sleep_duration;
        const awakePercentage = awakeSeconds / sleepSeconds * 100;
        const remPercentage = remSeconds / sleepSeconds * 100;

        content.querySelector('#app-dashboard-sleep-awake').style.width = `${awakePercentage}%`;
        content.querySelector('#app-dashboard-sleep-rem').style.width = `${remPercentage}%`;

        if (json.data && json.data['oura-sleep'] && json.data['oura-sleep'][1]) {
          const sleepData2 = JSON.parse(json.data['oura-sleep'][1].data);
          const sleepSeconds2 = sleepData2.total_sleep_duration;
  
          let trend = '';
          if (sleepSeconds === sleepSeconds2) {
            /*trend = `<span class="bg-warning p-2 rounded-circle">
                             <i class="fa-solid fa-arrow-right fa-fw"></i>
                           </span>`;*/
            trend = `<img alt="" src="${trendUrlYellow}" style="width: 0.5rem;">`;
          } else if (sleepSeconds > sleepSeconds2) {
            /*trend = `<span class="bg-success p-2 rounded-circle">
                             <i class="fa-solid fa-arrow-trend-up fa-fw"></i>
                           </span>`;*/
            trend = `<img alt="" src="${trendUrlGreen}" style="width: 0.5rem;">`;
          } else if (sleepSeconds < sleepSeconds2) {
            /*trend = `<span class="bg-danger p-2 rounded-circle">
                             <i class="fa-solid fa-arrow-trend-down fa-fw"></i>
                           </span>`;*/
            trend = `<img alt="" src="${trendUrlRed}" style="width: 0.5rem;">`;
          }
  
          content.querySelector('#app-view-dashboard-sleep-trend').innerHTML = trend;
          // console.log(sleepData2);
        }
      }

      if (json.data && json.data['oura-daily_readiness'] && json.data['oura-daily_readiness'][0]) {
        const readinessData = JSON.parse(json.data['oura-daily_readiness'][0].data);

        content.querySelector('#app-dashboard-activity').innerHTML = `${readinessData.contributors.previous_day_activity}%`;
        content.querySelector('#app-dashboard-heartRate').innerHTML = `<i class="fa-solid fa-heart"></i> ${readinessData.contributors.resting_heart_rate}`;

        console.log(readinessData);

        if (json.data && json.data['oura-daily_readiness'] && json.data['oura-daily_readiness'][1]) {
          const readinessData2 = JSON.parse(json.data['oura-daily_readiness'][1].data);
          let trend = '';

          if (readinessData.contributors.previous_day_activity === readinessData2.contributors.previous_day_activity) {
            trend = `<img alt="" src="${trendUrlYellow}" style="width: 0.5rem;">`;
          } else if (readinessData.contributors.previous_day_activity > readinessData2.contributors.previous_day_activity) {
            trend = `<img alt="" src="${trendUrlGreen}" style="width: 0.5rem;">`;
          } else if (readinessData.contributors.previous_day_activity < readinessData2.contributors.previous_day_activity) {
            trend = `<img alt="" src="${trendUrlRed}" style="width: 0.5rem;">`;
          }
          content.querySelector('#app-dashboard-activity-trend').innerHTML = trend;

          if (readinessData.contributors.resting_heart_rate === readinessData2.contributors.resting_heart_rate) {
            trend = `<img alt="" src="${trendUrlYellow}" style="width: 0.5rem;">`;
          } else if (readinessData.contributors.resting_heart_rate > readinessData2.contributors.resting_heart_rate) {
            trend = `<img alt="" src="${trendUrlGreen}" style="width: 0.5rem;">`;
          } else if (readinessData.contributors.resting_heart_rate < readinessData2.contributors.resting_heart_rate) {
            trend = `<img alt="" src="${trendUrlRed}" style="width: 0.5rem;">`;
          }
          content.querySelector('#app-dashboard-heartRate-trend').innerHTML = trend;
        }

        // chart...
        const chart = content.querySelector('#app-dashboard-activity-chart');
        const chartData = [];
        const chartLabels = [];

        chartData.push(JSON.parse(json.data['oura-daily_readiness'][0].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][1].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][2].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][3].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][4].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][5].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][6].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][7].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][8].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][9].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][10].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][11].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][12].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][13].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][14].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][15].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][16].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][17].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][18].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][19].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][20].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][21].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][22].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][23].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][24].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][25].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][26].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][27].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][28].data).contributors.previous_day_activity);
        chartData.push(JSON.parse(json.data['oura-daily_readiness'][29].data).contributors.previous_day_activity);

        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][0].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][1].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][2].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][3].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][4].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][5].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][6].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][7].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][8].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][9].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][10].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][11].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][12].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][13].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][14].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][15].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][16].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][17].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][18].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][19].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][20].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][21].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][22].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][23].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][24].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][25].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][26].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][27].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][28].data).day);
        chartLabels.push(JSON.parse(json.data['oura-daily_readiness'][29].data).day);

        new Chart(chart, {
          data: {
            datasets: [{
              data: chartData.reverse(),
              label: 'Activity',
            }],
            labels: chartLabels.reverse(),
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  display: false,
                },
              },
            },
          },
          type: 'bar',
        });


        // chart2...
        const chart2 = content.querySelector('#app-dashboard-heartRate-chart');
        const chartData2 = [];
        const chartLabels2 = [];

        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][0].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][1].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][2].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][3].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][4].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][5].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][6].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][7].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][8].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][9].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][10].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][11].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][12].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][13].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][14].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][15].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][16].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][17].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][18].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][19].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][20].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][21].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][22].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][23].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][24].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][25].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][26].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][27].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][28].data).contributors.previous_day_activity);
        chartData2.push(JSON.parse(json.data['oura-daily_readiness'][29].data).contributors.previous_day_activity);

        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][0].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][1].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][2].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][3].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][4].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][5].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][6].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][7].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][8].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][9].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][10].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][11].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][12].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][13].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][14].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][15].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][16].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][17].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][18].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][19].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][20].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][21].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][22].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][23].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][24].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][25].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][26].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][27].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][28].data).day);
        chartLabels2.push(JSON.parse(json.data['oura-daily_readiness'][29].data).day);

        new Chart(chart2, {
          data: {
            datasets: [{
              data: chartData2.reverse(),
              fill: true,
              label: 'Heart rate',
            }],
            labels: chartLabels2.reverse(),
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: {
                  display: false,
                },
              },
              y: {
                position: 'right',
                ticks: {
                  color: 'white',
                },
                // beginAtZero: true,
                /*
                ticks: {
                  display: false,
                },
                */
              },
            },
          },
          type: 'line',
        });
      }
    });

  const form = content.querySelector('#app-form-dashboard-dailySurvey');
  form.addEventListener('submit', (e) => {
    e.preventDefault();

    // Object.fromEntries(formDataProcessed.entries())

    const formData = Object.fromEntries(new FormData(form).entries());
    const formDataProcessed = {
      data: {
        score: formData.score,
        text: formData.text,
      },
      type: formData.type,
    }

    /*
    console.log(formData);
    console.log(formDataProcessed);
    console.log(JSON.stringify(formDataProcessed));
    */

    fetch(form.action, {
      body: JSON.stringify(formDataProcessed),
      headers: app.run.headers,
      method: form.method,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        if (json._token) {
          app.lib.token.set(json._token);
          myModal.hide();
          if (app.run.tokenObject.user && app.run.tokenObject.user.email && app.run.tokenObject.user.email !== '') {
            //
          } else {
            throw new Error('Undefined error.');
          }
        } else {
          throw new Error('Undefined error.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  return content;
};
