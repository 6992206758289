/* globals Chart */
import view from 'bundle-text:../dom/healthData.html'; // eslint-disable-line
import nav from 'bundle-text:../dom/_nav.html'; // eslint-disable-line
import app from './app';

export default () => {
  // const footer = document.querySelector('#app-footer');
  // footer.classList.remove('position-absolute');

  const container = document.querySelector('#app-content');
  container.classList.remove('align-items-center');
  container.classList.remove('d-flex');

  const content = document.createElement('div');
  /*
  content.classList.add('align-items-center');
  content.classList.add('d-flex');
  content.classList.add('justify-content-center');
  content.classList.add('min-h-100');
  content.classList.add('w-100');
  */
  content.innerHTML = view;

  content.querySelector('#app-nav').innerHTML = nav;
  content.querySelector('.nav-link').classList.remove('active');
  content.querySelector(`[href="${window.location.hash}"]`).classList.add('active');

  document.title = `Health data | ${app.conf.title}`;

  fetch('/api/data', { headers: app.run.headers })
    .then(response => response.json())
    .then((json) => {
      app.lib.token.set(json._token);

      if (json.data && json.data.allergy && json.data.allergy[0]) {
        content.querySelector('#app-form-healthData-allergy-value').value = json.data.allergy[0].data;
      }
      if (json.data && json.data.goal && json.data.goal[0]) {
        content.querySelector('#app-form-healthData-goal-value').value = json.data.goal[0].data;
      }
      if (json.data && json.data.height && json.data.height[0]) {
        content.querySelector('#app-form-healthData-height-value').value = json.data.height[0].data;
      }
      if (json.data && json.data.medication && json.data.medication[0]) {
        content.querySelector('#app-form-healthData-medication-value').value = json.data.medication[0].data;
      }
      if (json.data && json.data['oura-token'] && json.data['oura-token'][0]) {
        content.querySelector('#app-form-healthData-oura-value').value = json.data['oura-token'][0].data;
      }
      if (json.data && json.data.procedure && json.data.procedure[0]) {
        content.querySelector('#app-form-healthData-procedure-value').value = json.data.procedure[0].data;
      }
      /*
      if (json.data && json.data.smoking && json.data.smoking[0]) {
        if (json.data.smoking[0].data === '0') {
          content.querySelector('#app-form-healthData-smoking-value0').checked = true;
        } else if (json.data.smoking[0].data === '1') {
          content.querySelector('#app-form-healthData-smoking-value1').checked = true;
        }
      }
      */
      if (json.data && json.data.underlyingDisease && json.data.underlyingDisease[0]) {
        content.querySelector('#app-form-healthData-underlyingDisease-value').value = json.data.underlyingDisease[0].data;
      }
      if (json.data && json.data.weight && json.data.weight[0]) {
        content.querySelector('#app-form-healthData-weight-value').value = json.data.weight[0].data;
      }
      if (json.data && json.data.workType && json.data.workType[0]) {
        content.querySelector('#app-form-healthData-workType-value').value = json.data.workType[0].data;
      }
    });

  const forms = content.querySelectorAll('form');
  Array.from(forms).forEach((form) => {
    form.addEventListener('submit', (e) => {
      e.preventDefault();

      const formData = new FormData(form);

      fetch(form.action, {
        body: JSON.stringify(Object.fromEntries(formData.entries())),
        headers: app.run.headers,
        method: form.method,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((json) => {
          if (json._token) {
            app.lib.token.set(json._token);
            if (app.run.tokenObject.user && app.run.tokenObject.user.email && app.run.tokenObject.user.email !== '') {
              //
            } else {
              throw new Error('Undefined error.');
            }
          } else {
            throw new Error('Undefined error.');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  });

  const data = [
    {
      data: 79,
      date: '2023-08-15',
    },
    {
      data: 81,
      date: '2023-08-17',
    },
    {
      data: 84,
      date: '2023-08-19',
    },
    {
      data: 83,
      date: '2023-08-21',
    },
    {
      data: 85,
      date: '2023-08-26',
    },
  ];

  const canvas = document.createElement('canvas');
  const canvasContainer = content.querySelector('#app-charts');
  // eslint-disable-next-line
  const chart = new Chart(
    canvas,
    {
      data: {
        datasets: [
          {
            data: data.map(row => row.data),
            label: 'Weight (kg)',
          },
        ],
        labels: data.map(row => row.date),
      },
      type: 'line',
    },
  );

  canvasContainer.append(canvas);

  return content;
};
